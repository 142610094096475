// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cqi_my1Xo", "NR8Ioa_z3", "Sy8T78drd"];

const variantClassNames = {cqi_my1Xo: "framer-v-14a9rl7", NR8Ioa_z3: "framer-v-jmyyte", Sy8T78drd: "framer-v-17fr02v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "cqi_my1Xo", "Variant 2": "NR8Ioa_z3", "Variant 3": "Sy8T78drd"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, B85xb3w1T: title ?? props.B85xb3w1T ?? "Domov", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cqi_my1Xo"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, B85xb3w1T, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cqi_my1Xo", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter11c1pl = activeVariantCallback(async (...args) => {
setVariant("NR8Ioa_z3")
})

const onTapsr7avb = activeVariantCallback(async (...args) => {
setVariant("Sy8T78drd")
})

const onMouseLeavesr7avb = activeVariantCallback(async (...args) => {
setVariant("Sy8T78drd")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-b3hFS", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({NR8Ioa_z3: {href: {webPageId: "augiA20Il"}}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-14a9rl7", className)} framer-fq6ugs`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"cqi_my1Xo"} onMouseEnter={onMouseEnter11c1pl} ref={ref} style={{...style}} {...addPropertyOverrides({NR8Ioa_z3: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeavesr7avb, onTap: onTapsr7avb}, Sy8T78drd: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Domov</motion.p></React.Fragment>} className={"framer-1k0w18m"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"dpx2gLIj3"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", textShadow: "none"}} text={B85xb3w1T} transformTemplate={transformTemplate} variants={{NR8Ioa_z3: {textShadow: "0px 0px 7px rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-b3hFS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b3hFS .framer-fq6ugs { display: block; }", ".framer-b3hFS .framer-14a9rl7 { height: 24px; overflow: visible; position: relative; width: 54px; }", ".framer-b3hFS .framer-1k0w18m { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-b3hFS.framer-v-jmyyte .framer-14a9rl7 { cursor: pointer; text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"NR8Ioa_z3":{"layout":["fixed","fixed"]},"Sy8T78drd":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"B85xb3w1T":"title"}
 * @framerImmutableVariables false
 */
const Framerv3qfyinpc: React.ComponentType<Props> = withCSS(Component, css, "framer-b3hFS") as typeof Component;
export default Framerv3qfyinpc;

Framerv3qfyinpc.displayName = "Domov c";

Framerv3qfyinpc.defaultProps = {height: 24, width: 54};

addPropertyControls(Framerv3qfyinpc, {variant: {options: ["cqi_my1Xo", "NR8Ioa_z3", "Sy8T78drd"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, B85xb3w1T: {defaultValue: "Domov", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerv3qfyinpc, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/v3qfyinpc:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}])